import React, { useEffect, useRef } from 'react';

interface StreetViewProps {
  position: {
    lat: number;
    lng: number;
  };
}

const StreetView: React.FC<StreetViewProps> = ({ position }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (window.google) {
      // eslint-disable-next-line no-new
      new window.google.maps.StreetViewPanorama(ref.current!, {
        position,
        pov: { heading: 165, pitch: 0 },
        zoom: 1
      });
    }
  }, [position]);

  return <div ref={ref} id="street_view" />;
};

export default StreetView;
