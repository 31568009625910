import React, { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { debounce } from 'lodash';
import LazyLoad from 'react-lazyload';

import { styled } from 'styles';
import notify from 'notify';
import { Button, Dropdown, ListingCard, ListingCardSkeleton } from 'components';
import useLayout from 'hooks/useLayout';
import { getSimilarListings } from 'api/listings';

type FilterType = 'recommended' | 'price' | 'location' | 'sold_nearby';

const filterOptions: Array<{ text: string; value: FilterType }> = [
  {
    text: 'Recommended',
    value: 'recommended'
  },
  {
    text: 'Price',
    value: 'price'
  },
  {
    text: 'Location',
    value: 'location'
  },
  {
    text: 'Sold Nearby',
    value: 'sold_nearby'
  }
];

interface SimilarPropertiesProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  data: ListingDetails;
}

const SimilarProperties: React.FC<SimilarPropertiesProps> = ({ data }) => {
  const layout = useLayout();
  const { price, county, status } = data;
  const [activeFilter, setActiveFilter] = useState<FilterType>('recommended');
  const [listings, setListings] = useState<ListingCardData[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const selectedValue = filterOptions.find(option => option.value === activeFilter)?.text;

  const filters = useMemo(
    () => ({
      recommended: {
        listing_price_min: Math.round(price * 0.7),
        listing_price_max: Math.round(price * 1.3),
        county
      },
      price: {
        listing_price_min: Math.round(price * 0.9),
        listing_price_max: Math.round(price * 1.1)
      },
      location: {
        county
      },
      sold_nearby: {
        listing_status: 'Closed',
        county
      }
    }),
    [price, county, status]
  );

  const getListings = async () => {
    setLoading(true);
    try {
      const listings = await getSimilarListings(filters[activeFilter]);
      setListings(listings);
    } catch (err) {
      notify(err.message);
    }
    setLoading(false);
  };

  const handleSelectFilter = (value: FilterType, onSelect?: () => void) => {
    setActiveFilter(value);
    if (onSelect) onSelect();
  };

  useEffect(() => {
    const debouncedSearch = debounce(getListings, 200);
    debouncedSearch();
  }, [activeFilter]);

  const displayListings = useMemo(() => {
    if (layout === 'mobile') return listings?.slice(0, 2);
    if (layout === 'tablet') return listings?.slice(0, 4);
    return listings?.slice(0, 6);
  }, [layout, listings]);

  const renderFilters = () => {
    if (layout === 'mobile') {
      return (
        <StyledFilter
          value={selectedValue}
          placeholder="Select"
          data-cy="select_similar-properties"
          render={({ close }) => (
            <ul className="similar-properties__filters">
              {filterOptions.map(({ value, text }) => (
                <li
                  className="similar-properties__filter"
                  key={value}
                  onClick={() => handleSelectFilter(value, close)}
                  data-cy={`select_option-${value}`}>
                  {text}
                </li>
              ))}
            </ul>
          )}
        />
      );
    }

    return (
      <div className="similar-properties__filters">
        {filterOptions.map(({ value, text }) => (
          <Button
            className={clsx('similar-properties__filter', { active: value === activeFilter })}
            key={value}
            onClick={() => setActiveFilter(value)}
            data-cy={`button_${value}`}>
            {text}
          </Button>
        ))}
      </div>
    );
  };

  return (
    <StyledSimilarProperties>
      {renderFilters()}
      <div className="similar-properties__grid" id="lazy-scroll-container">
        {loading ? (
          Array(6)
            .fill(undefined)
            .map((_, idx) => (
              <ListingCardSkeleton
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                style={{ width: '100%', maxWidth: 420, margin: 'auto' }}
              />
            ))
        ) : (
          <>
            {displayListings?.map(listing => (
              // <ListingCard data={listing} key={listing.id} className="similar-properties__card" />
              <LazyLoad
                key={listing.id}
                height={276}
                scrollContainer={document.querySelector('#lazy-scroll-container') || undefined}>
                <ListingCard data={listing} className="similar-properties__card" />
              </LazyLoad>
            ))}
            {displayListings?.length === 0 && (
              <p className="similar-properties__no-results">No similar properties</p>
            )}
          </>
        )}
      </div>
    </StyledSimilarProperties>
  );
};

export default React.memo(SimilarProperties);

const StyledSimilarProperties = styled.div`
  .similar-properties__filters {
    display: flex;
    margin-bottom: 24px;
  }

  .similar-properties__filter {
    width: 144px;
    height: 39px;
    margin-right: 12px;

    &.active {
      background: #000;
      border-color: #000;
      color: #fff;
    }
  }

  .similar-properties__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 18px;
    grid-row-gap: 16px;
  }

  .similar-properties__card {
    width: auto;
    height: auto;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .similar-properties__filters {
      margin-bottom: 16px;
    }

    .similar-properties__filter {
      flex: 1;
    }

    .similar-properties__grid {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 16px;
      margin-top: 40px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .similar-properties__grid {
      grid-template-columns: 100%;
      grid-row-gap: 24px;
      margin-top: 40px;
    }

    .similar-properties__card {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .similar-properties__filters {
      display: block;
      margin: 0;
      padding-left: 0;
      list-style: none;
    }

    .similar-properties__filter {
      width: 100%;
      height: initial;
      padding: 8px 0;
      display: flex;
      border: none;
      cursor: pointer;
    }
  }
`;

const StyledFilter = styled(Dropdown)`
  display: initial;

  .dropdown__button {
    width: 100%;
    background: #000;
  }

  .dropdown__text {
    font-weight: 500;
    line-height: 14px;
    color: #fff;
  }
`;
