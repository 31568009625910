import React from 'react';
import { useLocation } from '@reach/router';
import { useForm } from 'react-hook-form';
import { FacebookShareButton, PinterestShareButton, TwitterShareButton } from 'react-share';

import { shareListing } from 'api/listings';
import notify from 'notify';
import { styled } from 'styles';
import { Modal, ModalProps, Input, Button, Icon } from 'components';
import { tryGetFirstError } from 'utils/requests';

import { cludfrontApi } from 'utils/env';
// const cludfrontApi = process.env.CLOUDFRONT_API || '';

interface SharePopupProps extends ModalProps {
  listing: ListingDetails;
}

const SharePopup: React.FC<SharePopupProps> = ({ listing, onClose, ...props }) => {
  const location = useLocation();
  const url = location.origin + location.pathname;
  const pinterestMedia = `${cludfrontApi}/${listing.images[0]}`;

  const { register, handleSubmit, errors } = useForm();

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(url);
      notify('Link copied to clipboard');
    } catch (err) {
      notify(`Couldn't copy link to clipboard`);
    }
  };

  const submit = async values => {
    try {
      await shareListing(values.emails, url);
      notify('Listing shared!');
      onClose?.();
    } catch (err) {
      notify(tryGetFirstError(err.response) || err.message);
    }
  };

  return (
    <StyledModal modalTitle="Share listing" onClose={onClose} {...props}>
      <form onSubmit={handleSubmit(submit)}>
        <div className="error-msg">{errors.emails?.message}</div>
        <Input
          ref={register({
            required: true,
            // emails separated by commas
            pattern: {
              value: /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/,
              message: 'Invalid format. Enter valid email or multiple emails separated by commas'
            }
          })}
          name="emails"
          error={errors.emails}
          label="Via email"
          placeholder="Recipients Emails"
        />
        <Button className="submit" primary>
          Send
        </Button>
      </form>
      <hr />
      Via social networks
      <div className="social-buttons">
        <FacebookShareButton className="social-button" url={url} resetButtonStyle={false}>
          <Icon name="social-facebook" />
        </FacebookShareButton>
        <PinterestShareButton
          className="social-button"
          url={url}
          media={pinterestMedia}
          resetButtonStyle={false}>
          <Icon name="social-pinterest" />
        </PinterestShareButton>
        <TwitterShareButton className="social-button" url={url} resetButtonStyle={false}>
          <Icon name="social-twitter" />
        </TwitterShareButton>
        <Button onClick={copyLink} className="social-button">
          <Icon name="link" />
        </Button>
      </div>
    </StyledModal>
  );
};

export default SharePopup;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 420px;
    font-size: 14px;
    line-height: 20px;
    padding: 20px 30px;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      width: 360px;
      padding: 20px 16px;
    }

    .modal-head {
      .title {
        display: block;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin: 16px 0;
        text-align: center;
      }
    }

    form {
      & > .error-msg {
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        color: ${props => props.theme.colors.red};
        margin-bottom: 8px;

        .icon {
          margin-right: 4px;
        }
      }

      .input,
      .button {
        width: 100%;
      }

      .submit {
        margin-top: 16px;
      }
    }

    hr {
      margin: 20px 0 16px 0;
    }

    .social-buttons {
      margin-top: 12px;

      .social-button {
        border-radius: 3px;
        border: 1px solid ${props => props.theme.colors.borderColor};
        display: inline-block;
        background: none;
        width: 36px;
        height: 36px;
        padding: 10px;
        margin-right: 12px;
        cursor: pointer;
        outline: none;
      }
    }
  }
`;
