import React, { useState } from 'react';
import { styled } from 'styles';
import { Icon, Button } from 'components';
import clsx from 'clsx';
import Map, { Marker } from 'components/Map';
import useLayout from 'hooks/useLayout';
import StreetView from './StreetView';
import Photos from './Photos';

type TabValue = 'photos' | 'street_view' | 'map_view';

interface FullScreenViewProps {
  defaultTab: TabValue;
  listing: ListingDetails;
  onReturnClick?: () => void;
  onModalButtonClick?: (type: 'schedule-tour' | 'contact-agent') => void;
}

const tabs: { value: TabValue; text: string }[] = [
  { value: 'photos', text: 'Photos' },
  { value: 'street_view', text: 'Street View' },
  { value: 'map_view', text: 'Map View' }
];

const ListingFullScreenView: React.FC<FullScreenViewProps> = ({
  listing,
  defaultTab,
  onReturnClick,
  onModalButtonClick
}) => {
  const layout = useLayout();
  const [tab, setTab] = useState(defaultTab);
  const position = { lat: Number(listing.latitude), lng: Number(listing.longitude) };
  const displayTabs = listing.latitude && listing.longitude ? [...tabs] : [tabs.shift()];

  const handleModalButtonClick = e => {
    onModalButtonClick!(e.target.value);
    onReturnClick!();
  };

  const getTabContent = () => {
    switch (tab) {
      case 'photos':
        return (
          <>
            <div className="photos-view">
              <Photos items={listing.images} />
            </div>
            {layout === 'mobile' && (
              <div className="bottom-buttons">
                <Button primary value="schedule-tour" onClick={handleModalButtonClick}>
                  Schedule Tour
                </Button>
                <Button primary value="contact-agent" onClick={handleModalButtonClick}>
                  Contact Agent
                </Button>
              </div>
            )}
          </>
        );
      case 'street_view':
        return (
          <div className="street-view">
            <StreetView position={position} />
          </div>
        );
      case 'map_view':
        return (
          <div className="map-view">
            <Map center={position}>
              <Marker {...position} />
            </Map>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <StyledListingFullScreenView className="listing full-screen">
      <header className="mobile">
        <div className="return-container">
          <StyledButton onClick={onReturnClick} className="return">
            <Icon name="chevron" size={20} /> Back
          </StyledButton>
        </div>
        <div className="buttons-middle">
          {displayTabs.map(item => (
            <StyledButton
              key={item.value}
              onClick={() => setTab(item.value)}
              className={clsx({ active: tab === item.value })}>
              {item.text}
            </StyledButton>
          ))}
        </div>
      </header>
      {getTabContent()}
    </StyledListingFullScreenView>
  );
};

export default ListingFullScreenView;

const StyledListingFullScreenView = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;

  header {
    .return-container {
      display: inline-flex;
      align-items: center;

      button {
        display: flex;
        align-items: center;

        .icon {
          transform: rotate(90deg);
          margin-right: 10px;
        }
      }
    }

    .buttons-middle {
      display: flex;
      align-items: center;

      button {
        color: #979797;

        &.active {
          color: ${props => props.theme.colors.primary};
          text-decoration: underline;
        }

        &:not(:last-of-type) {
          margin-right: 20px;
        }
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      display: flex;
      height: 72px;
      padding: 0 20px;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

      button {
        background: none;
        border: none;
        padding: 0;
        color: ${props => props.theme.colors.primary};
        fill: ${props => props.theme.colors.primary};
        font-size: 16px;
        line-height: 24px;
        outline: none;
        cursor: pointer;

        &.return {
          display: flex;
          align-items: center;
          position: absolute;
          left: 20px;

          .icon {
            transform: rotate(90deg);
            margin-right: 16px;
          }
        }
      }

      .buttons-middle {
        margin: 0 auto;
      }
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      flex-direction: column;
      align-items: flex-start;

      .return-container {
        padding: 0 8px;
        height: 56px;
      }

      button.return {
        position: initial;
      }

      .buttons-middle {
        height: 36px;
        padding: 0 16px;
        border-top: 1px solid ${props => props.theme.colors.creamyGray};

        button {
          height: 100%;

          &:not(:last-of-type) {
            margin-right: 11px;
          }

          &.active {
            text-decoration: none;
            border-bottom: 2px solid ${props => props.theme.colors.primary};
          }
        }
      }
    }
  }

  .photos-view {
    height: 100%;
    padding: 20px;
    overflow: auto;
  }

  .street-view {
    height: 100%;
    box-sizing: border-box;
    padding: 20px 100px 74px 100px;

    #street_view {
      width: 100%;
      height: 100%;
    }
  }

  .map-view {
    padding: 20px 100px 74px 100px;

    .map {
      height: 100%;
    }
  }

  .bottom-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    padding: 0 24px;

    button {
      width: 100%;
      height: 36px;

      &:first-of-type {
        margin-right: 14px;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .photos-view,
    .street-view,
    .map-view {
      padding: 0;
    }
  }
`;

const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: ${props => props.theme.colors.primary};
  fill: ${props => props.theme.colors.primary};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  cursor: pointer;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  }
`;
