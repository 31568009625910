/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useMemo } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import useLayout from 'hooks/useLayout';

import { styled } from 'styles';
import { Button, ContactForm, Icon } from 'components';
import { getAddress } from 'utils/listing';
import OfferBlock from './OfferBlock';

interface ListingContactProps extends React.HTMLAttributes<HTMLFormElement> {
  listingData: ListingDetails;
  contactAgent?: boolean;
  cancel?: () => void;
}

const getDatesRange = (startDate: moment.Moment = moment(), range = 5) => {
  return Array(range)
    .fill(undefined)
    .map((_, idx) => moment(startDate).add(idx, 'days'));
};

const ListingContact: React.FC<ListingContactProps> = ({ listingData, contactAgent, cancel }) => {
  const layout = useLayout();
  const [emailType, setEmailType] = useState<'schedule' | 'contact'>(
    contactAgent ? 'contact' : 'schedule'
  );
  const [tourDate, setTourDate] = useState<moment.Moment>(moment());
  const [dates, setDates] = useState<moment.Moment[]>(getDatesRange(moment()));
  const [tourType, setTourType] = useState<TourType>();
  const startDate = dates[0];
  const disablePrevButton = useMemo(() => startDate.isBefore(moment()), [startDate]);
  const scheduleTour = emailType === 'schedule';
  const submitButtonText = scheduleTour ? 'Schedule Showing' : 'Contact';

  const handleChangeDates = (direction: 'prev' | 'next') => () => {
    let dates: moment.Moment[];

    if (direction === 'next') {
      dates = getDatesRange(startDate.clone().add(1, 'days'));
    } else {
      dates = getDatesRange(startDate.clone().subtract(1, 'days'));
    }

    setDates(dates);
  };

  const formValues = useMemo(() => {
    const values: Partial<ContactFormValues> = {
      source: `${listingData.address1}, ${getAddress(listingData)}`,
      listing_id: listingData.id,
      type: scheduleTour ? 'Schedule Tour' : 'Contact Request'
    };

    if (scheduleTour) {
      values.tour_type = tourType;
      values.tour_date = tourDate && tourDate.isValid() ? tourDate.format('MM/DD/YYYY') : undefined;
    }

    return values;
  }, [listingData, emailType, tourType, tourDate]);

  const formContent = (
    <StyledContactFormContainer className="contact-form">
      {!scheduleTour && <h3 className="listing-contact__title">Contact our agent to learn more</h3>}
      <h4 className="contact-form__title">{scheduleTour ? 'Tour Type' : 'Contact Agent'}</h4>
      {scheduleTour && (
        <>
          <div className="contact-form__type">
            <input
              type="radio"
              name="type"
              value="In Person"
              id="in_person"
              className="contact-form__type__input"
              data-cy="radio_in-person"
              onChange={({ target }) => setTourType(target.value as TourType)}
            />
            <label className="contact-form__type__label" htmlFor="in_person">
              In Person
            </label>
            <input
              type="radio"
              name="type"
              value="Virtual"
              id="virtual"
              className="contact-form__type__input"
              data-cy="radio_virtual"
              onChange={({ target }) => setTourType(target.value as TourType)}
            />{' '}
            <label className="contact-form__type__label" htmlFor="virtual">
              Virtual
            </label>
          </div>
          <StyledDatepicker className="contact-form__datepicker">
            <Button
              outline
              onClick={handleChangeDates('prev')}
              className={clsx('contact-form__datepicker__control prev', {
                disabled: disablePrevButton
              })}
              data-cy="button_prev-date">
              <Icon name="chevron" />
            </Button>
            <ul className="contact-form__datepicker__dates">
              {dates.map((date, idx) => (
                <li
                  className={clsx('contact-form__datepicker__date', {
                    active: tourDate.isSame(date),
                    disabled: date.isBefore(moment(), 'day')
                  })}
                  onClick={() => setTourDate(date)}
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  data-cy={date.format('MM/DD/YYYY')}>
                  <span className="contact-form__datepicker__date__day-of-week">
                    {date.format('ddd')}
                  </span>
                  <span className="contact-form__datepicker__date__day-of-month">
                    {date.format('DD')}
                  </span>
                  <span className="contact-form__datepicker__date__month">
                    {date.format('MMM')}
                  </span>
                </li>
              ))}
            </ul>
            <Button
              outline
              onClick={handleChangeDates('next')}
              className="contact-form__datepicker__control next"
              data-cy="button_next-date">
              <Icon name="chevron" />
            </Button>
          </StyledDatepicker>
        </>
      )}
      <StyledContactForm
        formValues={formValues}
        submitButtonText={submitButtonText}
        cancel={layout === 'mobile' ? cancel : undefined}
        isHiddenFormTitle
        isHiddenTelAgent
      />
    </StyledContactFormContainer>
  );

  if (layout === 'mobile') return <>{formContent}</>;

  return (
    <StyledListingContact className="listing-contact">
      <OfferBlock />
      <div className="listing-contact__tabs">
        <button
          className={clsx('listing-contact__tab', {
            active: emailType === 'schedule'
          })}
          onClick={() => setEmailType('schedule')}
          data-cy="button_schedule-tour">
          Schedule Tour
        </button>
        <button
          className={clsx('listing-contact__tab', {
            active: emailType === 'contact'
          })}
          onClick={() => setEmailType('contact')}
          data-cy="button_contact-agent">
          Contact Agent
        </button>
      </div>
      <div className="listing-contact__box">{formContent}</div>
    </StyledListingContact>
  );
};

export default React.memo(ListingContact);

const borderColor = props => props.theme.colors.borderColor;
const violet = props => props.theme.colors.primary;
const dustyGray = props => props.theme.colors.dustyGray;
const lightGray = props => props.theme.colors.lightGray;
const borderRadius = props => props.theme.misc.borderRadius;

const StyledListingContact = styled.div`
  position: sticky;
  top: 20px;

  .listing-contact__tabs {
    margin-bottom: 12px;

    .listing-contact__tab {
      display: inline-block;
      margin-right: 17px;
      font-size: 14px;
      font-weight: normal;
      line-height: 24px;
      color: ${dustyGray};
      padding: 0;
      background: none;
      border: none;
      text-decoration: none;
      outline: none;
      cursor: pointer;

      &.active {
        color: #000;
      }
    }
  }

  .listing-contact__box {
    width: 355px;
    padding: 12px;
    border: 1px solid ${lightGray};
    border-radius: ${borderRadius};
  }
`;

const StyledDatepicker = styled.div`
  display: flex;
  align-items: center;

  .contact-form__datepicker__control {
    width: 16px;
    height: 64px;
    padding: 0;
    border: none;

    &.prev {
      margin-left: -8px;
      .icon {
        transform: rotate(90deg);
      }
    }
    &.next {
      .icon {
        transform: rotate(-90deg);
      }
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .contact-form__datepicker__dates {
    display: flex;
    padding-left: 0;
    list-style: none;
  }

  .contact-form__datepicker__date {
    display: inline-block;
    margin-right: 8px;
    width: 56px;
    padding: 0 8px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    border: 1px solid ${borderColor};
    border-radius: ${borderRadius};
    cursor: pointer;
    &.active {
      border-color: ${violet};
    }
    &.disabled {
      pointer-events: none;
    }
    &:last-of-type {
      margin-right: 0;
    }

    & > span {
      display: block;
      margin-bottom: 1px;
      &:last-of-type {
        margin-bottom: 0;
      }

      &.__day-of-month {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .contact-form__datepicker__date {
      width: 50px;
    }
  }
`;

const StyledContactFormContainer = styled.div`
  .contact-form__title {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: normal;
    line-height: 20px;
    color: ${props => props.theme.colors.dustyGray};
  }

  .listing-contact {
    &__title {
      margin-bottom: 14px;
      font-size: 14px;
      font-weight: normal;
      line-height: 16px;
    }
  }

  .contact-form__type {
    position: relative;

    .contact-form__type__label {
      display: inline-block;
      width: 90px;
      padding: 6px 0;
      text-align: center;
      font-size: 12px;
      line-height: 24px;
      border: 1px solid ${borderColor};
      z-index: 5;
      position: relative;
      cursor: pointer;

      &:first-of-type {
        border-radius: ${borderRadius} 0 0 ${borderRadius};
      }
      &:last-of-type {
        margin-left: -6px;
        border-radius: 0 ${borderRadius} ${borderRadius} 0;
        z-index: 4;
      }
    }

    .contact-form__type__input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;

      &:checked + label {
        border-color: ${violet};
      }
      &:checked + label:last-of-type {
        z-index: 6;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .contact-form__title {
      margin-top: 0;
    }
  }
`;

const StyledContactForm = styled(ContactForm)`
  width: 100%;

  .contact-form__form {
    margin: 0;
  }

  .contact-form__textarea {
    margin: 0 0 16px;
  }

  .contact-form__button {
    width: 100%;
  }
`;
