import React, { useState } from 'react';
import clsx from 'clsx';
import pluralize from 'pluralize';
import { truncate } from 'lodash';
import { Link, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import Helmet from 'react-helmet';

import { styled } from 'styles';
import ListingLayout from 'layouts/ListingLayout';
import IndexLayout from 'layouts';
import { Button, Icon, Modal, NoSSR } from 'components';
import {
  ListingContact,
  FloatingListingContact,
  ListingNav,
  ListingMap,
  ListingTopSection,
  PaymentCalculator,
  SimilarProperties,
  ListingContextWrapper
} from 'views/ListingPage';
import useLayout from 'hooks/useLayout';
import FullScreenView from 'views/ListingPage/ListingFullScreenView';
import noImage from 'assets/images/placeholder_no_image_large.jpg';

import { cludfrontApi } from 'utils/env';
import { info } from 'sitedata';
import ListingCarousel from 'components/ListingCarousel';
import OfferBlock from 'views/ListingPage/OfferBlock';

// const cludfrontApi = process.env.CLOUDFRONT_API;
const siteName = info.title;
interface ListingDetailsProps extends React.HTMLAttributes<HTMLDivElement> {
  data: {
    listing: ListingDetails;
    isDynamic?: boolean;
  };
}

const ListingDetails: React.FC<ListingDetailsProps> = ({ data }) => {
  const { listing, isDynamic } = data;
  const { propertyDetails, listingAgent } = listing;
  const [showFullOverview, setShowFullOverview] = useState<boolean>(false);
  const [fullScreenView, setFullScreenView] = useState<'photos' | 'street_view' | 'map_view'>();
  const [openModal, setOpenModal] = useState<'schedule-tour' | 'contact-agent'>();
  const layout = useLayout();
  const readMoreLabel = `Read ${showFullOverview ? 'less' : 'more'}`;
  const closeModal = () => setOpenModal(undefined);
  const location = useLocation();
  const locationState = location.state as any;
  const goBackLabel = locationState?.search ? '⟵ Back to Search' : '⟵ Back to Main Page';
  const isSchoolsEpmty = Object.values(listing.schools).every(item => !item);
  const paymentValues: PaymentValues = {
    price: listing.price,
    hoa: listing.hoa,
    propertyTaxes: listing.propertyTaxes / 12,
    homeInsurance: listing.homeInsurance / 12
  };
  const showMap = listing.longitude && listing.latitude;

  const modalContent =
    openModal === 'schedule-tour' ? (
      <ListingContact listingData={listing} cancel={closeModal} />
    ) : (
      <ListingContact listingData={listing} cancel={closeModal} contactAgent />
    );

  const getLayout = () => {
    if (fullScreenView)
      return (
        <FullScreenView
          listing={listing}
          defaultTab={fullScreenView}
          onReturnClick={() => setFullScreenView(undefined)}
          onModalButtonClick={setOpenModal}
        />
      );

    return (
      <ListingContextWrapper listing={listing}>
        <ListingLayout>
          {isDynamic && (
            <div style={{ display: 'none' }}>THIS PAGE CONTENT IS GENERATED DYNAMICALLY</div>
          )}
          <StyledListing className="listing">
            <NoSSR>
              {layout === 'desktop' && (
                <>
                  <Link
                    to={locationState?.search ? `/search${locationState.search}` : '/'}
                    className="back-to-search"
                    data-cy="button_back-to-search">
                    {goBackLabel}
                  </Link>
                  <ListingTopSection listing={listing} />
                  <ListingNav hasSchools={listing.schools && !isSchoolsEpmty} />
                </>
              )}
            </NoSSR>

            <div className="listing__container">
              <main className="listing__main">
                <section className="listing-details media" id="overview">
                  <div className="media__images">
                    <ListingCarousel images={listing.images} />
                    <Button
                      className="button-full-view"
                      simple
                      onClick={() => setFullScreenView('photos')}
                      data-cy="button_full-view">
                      <Icon name="full-view" />
                    </Button>
                  </div>

                  {showMap && (
                    <div className="media__buttons">
                      <StyledButton
                        onClick={() => setFullScreenView('street_view')}
                        data-cy="button_street-view">
                        <Icon name="street-view" width={18} height={16} />
                        Street View
                      </StyledButton>
                      <StyledButton
                        onClick={() => setFullScreenView('map_view')}
                        data-cy="button_map-view">
                        <Icon name="map-view" width={16} height={16} />
                        Map View
                      </StyledButton>
                    </div>
                  )}
                </section>

                <NoSSR>{layout !== 'desktop' && <ListingTopSection listing={listing} />}</NoSSR>
                {layout !== 'desktop' && <OfferBlock />}

                <section className="listing-details overview">
                  <h3 className="listing-details__title">Overview</h3>
                  <p className="overview__description" data-cy="description">
                    {showFullOverview
                      ? listing.description
                      : truncate(listing.description, {
                          length: 200
                        })}
                    <button
                      className={clsx('read-more', { open: showFullOverview })}
                      onClick={() => setShowFullOverview(v => !v)}>
                      {readMoreLabel}
                    </button>
                  </p>
                  {listing.listedBy && (
                    <div className="overview__listed" data-cy="listed-by">
                      Listed by {listing.listedBy}
                    </div>
                  )}
                </section>

                {listingAgent?.name && (
                  <section className="listing-details agent" id="agent">
                    <h3 className="listing-details__title">Listing Agent</h3>
                    <div className="agent__info">
                      <div className="agent__title">
                        <div className="listing-details__info">{listingAgent.name}</div>
                        <div className="listing-details__info listing-details__agency">
                          {listingAgent.agency}
                        </div>
                      </div>
                      <div className="agent__contact">
                        {listingAgent.officePhone && (
                          <dl className="listing-details__info">
                            <dt className="listing-details__name">Office Phone:&nbsp;</dt>
                            <dd className="listing-details__value" data-cy="agent_phone">
                              <a
                                href={`tel:${listingAgent.officePhone}`}
                                className="listing-details__link">
                                {listingAgent.officePhone}
                              </a>
                            </dd>
                          </dl>
                        )}
                        {listingAgent?.mobilePhone && (
                          <dl className="listing-details__info">
                            <dt className="listing-details__name">Mobile Phone:&nbsp;</dt>
                            <dd className="listing-details__value" data-cy="agent_phone">
                              <a
                                href={`tel:${listingAgent.mobilePhone}`}
                                className="listing-details__link">
                                {listingAgent.mobilePhone}
                              </a>
                            </dd>
                          </dl>
                        )}
                        {listingAgent?.email && (
                          <dl className="listing-details__info">
                            <dt className="listing-details__name">Email:&nbsp;</dt>
                            <dd className="listing-details__value" data-cy="agent_email">
                              {listingAgent.email.split(';').map(email => (
                                <a
                                  href={`mailto:${email}`}
                                  className="listing-details__link"
                                  key={email}>
                                  {email}
                                </a>
                              ))}
                            </dd>
                          </dl>
                        )}
                      </div>
                    </div>
                  </section>
                )}

                <section className="listing-details features" id="features">
                  <h3 className="listing-details__title">Features</h3>
                  <div className="listing-details__list">
                    <div className="listing-details__list-container">
                      <dl className="listing-details__info">
                        <dt className="listing-details__name">Status</dt>
                        <dd className="listing-details__value" data-cy="status">
                          {listing.status}
                        </dd>
                      </dl>

                      <dl className="listing-details__info">
                        <dt className="listing-details__name">Property Type</dt>
                        <dd className="listing-details__value" data-cy="property-type">
                          {listing.propertyType}
                        </dd>
                      </dl>

                      <dl className="listing-details__info">
                        <dt className="listing-details__name">Community</dt>
                        <dd className="listing-details__value" data-cy="neighborhood">
                          {listing.neighborhood}
                        </dd>
                      </dl>

                      <dl className="listing-details__info">
                        <dt className="listing-details__name">MLS#</dt>
                        <dd className="listing-details__value" data-cy="mls-id">
                          {listing.mlsId}
                        </dd>
                      </dl>
                    </div>
                    <div className="listing-details__list-container">
                      <dl className="listing-details__info">
                        <dt className="listing-details__name">Time on Website</dt>
                        <dd className="listing-details__value" data-cy="days-on-market">{`${
                          listing.daysOnMarket
                        } ${pluralize('day', listing.daysOnMarket)}`}</dd>
                      </dl>

                      {Boolean(propertyDetails.yearBuilt) && propertyDetails.yearBuilt! > 0 && (
                        <dl className="listing-details__info">
                          <dt className="listing-details__name">Year Built</dt>
                          <dd className="listing-details__value" data-cy="year-built">
                            {propertyDetails.yearBuilt}
                          </dd>
                        </dl>
                      )}

                      {propertyDetails.lotSize && (
                        <dl className="listing-details__info">
                          <dt className="listing-details__name">Lot Size</dt>
                          <dd className="listing-details__value" data-cy="lot-size">
                            {propertyDetails.lotSize}
                          </dd>
                        </dl>
                      )}
                    </div>
                  </div>
                </section>

                <section className="listing-details map" id="map">
                  <h3 className="listing-details__title">Location</h3>
                  <ListingMap listing={listing} />
                </section>

                {listing.schools && !isSchoolsEpmty && (
                  <section className="listing-details schools" id="schools">
                    <h3 className="listing-details__title">Schools</h3>
                    {Boolean(listing.schools) &&
                      Object.keys(listing.schools!).map(key => (
                        <dl className="listing-details__info" key={key}>
                          <dt className="listing-details__name" data-cy={key}>
                            {listing.schools![key]}
                          </dt>
                        </dl>
                      ))}
                  </section>
                )}

                <NoSSR>
                  {listing.price && (
                    <section className="listing-details affordability" id="affordability">
                      <h3 className="listing-details__title">Affordability</h3>

                      <PaymentCalculator data={paymentValues} />
                    </section>
                  )}
                </NoSSR>

                <section className="listing-details property-details" id="property-details">
                  <h3 className="listing-details__title">Property Details for Address</h3>
                  <div className="listing-details__box listview">
                    <div className="listview__header">Interior Features</div>

                    <figure className="listview__list__group">
                      <figcaption className="listview__list__caption">
                        Bedroom Information
                      </figcaption>
                      <ul className="listview__list">
                        <li className="listview__list__item" data-cy="beds-info">
                          # of Bedrooms: {propertyDetails.beds}
                        </li>
                      </ul>
                    </figure>

                    <figure className="listview__list__group">
                      <figcaption className="listview__list__caption">
                        Bathroom Information
                      </figcaption>
                      <ul className="listview__list">
                        <li className="listview__list__item" data-cy="baths-info">
                          # of Baths: {propertyDetails.baths}
                        </li>
                        <li className="listview__list__item" data-cy="baths-full">
                          # of Baths (Full): {propertyDetails.bathsFull}
                        </li>
                        {Boolean(propertyDetails.bathsHalf) && (
                          <li className="listview__list__item" data-cy="baths-half">
                            # of Baths (Half): {propertyDetails.bathsHalf}
                          </li>
                        )}
                      </ul>
                    </figure>

                    <figure className="listview__list__group">
                      <figcaption className="listview__list__caption">Heating & Cooling</figcaption>
                      <ul className="listview__list">
                        <li className="listview__list__item" data-cy="heat">
                          Heating: {propertyDetails.heat || 'N/A'}
                        </li>
                        <li className="listview__list__item" data-cy="cool">
                          Cooling: {propertyDetails.cool || 'N/A'}
                        </li>
                      </ul>
                    </figure>

                    {propertyDetails.petsAllowed && (
                      <figure className="listview__list__group">
                        <figcaption className="listview__list__caption">Pets Allowed</figcaption>
                        <ul className="listview__list">
                          <li className="listview__list__item" data-cy="pets-allowed">
                            {propertyDetails.petsAllowed}
                          </li>
                        </ul>
                      </figure>
                    )}

                    {propertyDetails.equipment && (
                      <figure className="listview__list__group">
                        <figcaption className="listview__list__caption">Equipment</figcaption>
                        <ul className="listview__list">
                          <li className="listview__list__item" data-cy="equipment">
                            {propertyDetails.equipment}
                          </li>
                        </ul>
                      </figure>
                    )}

                    {propertyDetails.appliances && (
                      <figure className="listview__list__group">
                        <figcaption className="listview__list__caption">Appliances</figcaption>
                        <ul className="listview__list">
                          <li className="listview__list__item" data-cy="appliances">
                            {propertyDetails.appliances}
                          </li>
                        </ul>
                      </figure>
                    )}
                  </div>

                  <div className="listing-details__box listview">
                    <div className="listview__header">
                      Parking / Garage, Exterior Features, School / Neighborhood, HOA Includes,
                      Utilities
                    </div>

                    {(propertyDetails.parkingFeatures ||
                      propertyDetails.parkingSpaces ||
                      propertyDetails.garageSpaces) && (
                      <figure className="listview__list__group">
                        <figcaption className="listview__list__caption">
                          Parking Information
                        </figcaption>
                        <ul className="listview__list">
                          {propertyDetails.parkingFeatures && (
                            <li className="listview__list__item" data-cy="parking-features">
                              {propertyDetails.parkingFeatures}
                            </li>
                          )}
                          {propertyDetails.parkingSpaces && (
                            <li className="listview__list__item" data-cy="parking-spaces">
                              # of Exterior Parking Spaces: {propertyDetails.parkingSpaces}
                            </li>
                          )}
                          {propertyDetails.garageType && (
                            <li className="listview__list__item" data-cy="garage-type">
                              Garage Type: {propertyDetails.garageType}
                            </li>
                          )}
                          {propertyDetails.garageSpaces && (
                            <li className="listview__list__item" data-cy="garage-spaces">
                              # of Garage Spaces: {propertyDetails.garageSpaces}
                            </li>
                          )}
                        </ul>
                      </figure>
                    )}

                    {propertyDetails.exteriorFeatures && (
                      <figure className="listview__list__group">
                        <figcaption className="listview__list__caption">
                          Exterior Features
                        </figcaption>
                        <ul className="listview__list">
                          <li className="listview__list__item" data-cy="exterior-features">
                            {propertyDetails.exteriorFeatures}
                          </li>
                        </ul>
                      </figure>
                    )}

                    {propertyDetails.hoaIncludes && (
                      <figure className="listview__list__group">
                        <figcaption className="listview__list__caption">HOA Includes</figcaption>
                        <ul className="listview__list">
                          <li className="listview__list__item" data-cy="hoa-includes">
                            {propertyDetails.hoaIncludes}
                          </li>
                        </ul>
                      </figure>
                    )}

                    {propertyDetails.utilities && (
                      <figure className="listview__list__group">
                        <figcaption className="listview__list__caption">Utilities</figcaption>
                        <ul className="listview__list">
                          {propertyDetails.utilities.sewer && (
                            <li className="listview__list__item" data-cy="utilities-sewer">
                              Sewer: {propertyDetails.utilities.sewer}
                            </li>
                          )}
                          {propertyDetails.utilities.water && (
                            <li className="listview__list__item" data-cy="utilities-water">
                              Water: {propertyDetails.utilities.water}
                            </li>
                          )}
                        </ul>
                      </figure>
                    )}
                  </div>
                </section>
              </main>
              <NoSSR>
                {layout !== 'desktop' ? (
                  <FloatingListingContact onButtonClick={setOpenModal} />
                ) : (
                  <aside className="listing__aside">
                    <ListingContact listingData={listing} />
                  </aside>
                )}
              </NoSSR>
            </div>
            <section className="listing-details similar-properties" id="similar-properties">
              <h3 className="listing-details__title">Similar Homes</h3>
              <SimilarProperties data={listing} />
            </section>
          </StyledListing>
        </ListingLayout>
      </ListingContextWrapper>
    );
  };

  const title = `${listing.address1}, ${listing.city}, ${listing.state} ${listing.postalCode} ${
    siteName ? `| ${siteName}` : ''
  }`;

  return (
    <IndexLayout>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={listing.description} />
        <meta property="og:url" content={location.origin + location.pathname} />
        {cludfrontApi && listing.images[0] && (
          <meta property="og:image" content={`${cludfrontApi}/${listing.images[0]}`} />
        )}
      </Helmet>
      {getLayout()}
      <Modal open={Boolean(openModal)} onClose={closeModal} modalHead={false}>
        {modalContent}
      </Modal>
    </IndexLayout>
  );
};

export default React.memo(ListingDetails);

export const query = graphql`
  query Listing($listingId: String!) {
    listing(id: { eq: $listingId }) {
      id
      mlsId
      status
      price
      images
      postalCode
      state
      city
      county
      neighborhood
      address1
      address2
      description
      latitude
      longitude
      daysOnMarket
      propertyType
      listingAgent {
        name
        officePhone
        mobilePhone
        email
        agency
      }
      propertyDetails {
        beds
        baths
        bathsFull
        bathsHalf
        sqft
        yearBuilt
        lotSize
        heat
        cool
        appliances
        equipment
        exteriorFeatures
        parkingFeatures
        parkingSpaces
        garageSpaces
        garageType
        hoaIncludes
        petsAllowed
        utilities {
          sewer
          water
        }
      }
      listedBy
      schools {
        elementary
        middleOrJunior
        high
      }
      propertyTaxes
      homeInsurance
      hoa
    }
  }
`;

const dustyGray = props => props.theme.colors.dustyGray;
const lightGray = props => props.theme.colors.lightGray;
const violet = props => props.theme.colors.primary;
const borderRadius = props => props.theme.misc.borderRadius;
const borderColor = props => props.theme.colors.borderColor;
const small = props => props.theme.breakpoints.sm;
const medium = props => props.theme.breakpoints.md;

const StyledListing = styled.div`
  height: 100%;
  width: 1122px;
  margin: 28px auto;
  box-sizing: border-box;

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    font-weight: normal;
  }

  .back-to-search {
    display: block;
    margin-bottom: 18px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${violet};
    text-decoration: none;
  }

  .listing-nav {
    margin: 1px 0 32px 0;
  }

  .read-more {
    font-size: 16px;
    line-height: 24px;
    color: ${violet};
    cursor: pointer;
    display: inline;
    background: transparent;
    border: none;
    outline: none;
  }

  .listing__container {
    display: flex;
  }

  .listing__main {
    flex: 1;

    .listing-carousel,
    .img-error {
      width: 100%;
      max-width: 728px;
      height: 536px;
    }

    .img-error {
      object-fit: cover;
    }
  }

  .listing__aside {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 401px;
    padding-left: 46px;
  }

  .listing-details {
    padding: 18px 0;
    font-size: 14px;
    line-height: 22px;

    .listing-details__title {
      margin-bottom: 16px;
      padding-bottom: 4px;
      font-size: 14px;
      font-weight: 500;
      border-bottom: 1px solid ${borderColor};
    }

    .listing-details__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      &__item {
        margin: 0 0 12px;
        font-size: 16px;
        line-height: 24px;
        color: #545454;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .listing-details__info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 0 0 12px 0;
      font-size: 16px;
      line-height: 22px;

      .listing-details__name {
        grid-column-start: 1;
        color: #545454;
      }

      .listing-details__value {
        grid-column-start: 2;
        margin: 0;
        font-weight: 500;
      }
    }

    .listing-details__box {
      border: 1px solid ${borderColor};
      border-radius: ${borderRadius};

      &:nth-of-type(2) {
        border-top: none;
        border-radius: 0 0 ${borderRadius} ${borderRadius};
      }
    }

    .listing-details__link {
      display: block;
      color: #000;
      text-decoration: none;
    }

    .tableview {
      display: table;
      width: 100%;

      .tableview__cell {
        display: table-cell;
        padding: 12px;
        font-size: 14px;
        line-height: 22px;
      }

      .tableview__header {
        display: table-header-group;
        border-bottom: 1px solid ${borderColor};

        .tableview__cell {
          font-weight: 500;
        }
      }

      .tableview__row {
        display: table-row;

        .tableview__cell {
          color: #545454;
          border-top: 1px solid ${borderColor};
        }
      }
    }

    .listview {
      .listview__header {
        padding: 12px;
        font-weight: 500;
        border-bottom: 1px solid ${borderColor};
      }

      .listview__list__group {
        margin: 6px 0;
        padding: 0 12px;
      }

      .listview__list__caption {
        margin-bottom: 4px;
      }

      .listview__list {
        margin: 0;
        padding-left: 26px;

        .listview__list__item {
          margin-bottom: 4px;
          color: #545454;
          word-break: break-word;
        }
      }
    }

    &.media {
      position: relative;
      padding-top: 0;

      .media__street-view,
      .media__map-view {
        height: 500px;
      }

      .media__street-view > * {
        height: 100%;
      }

      .button-full-view {
        position: absolute;
        top: 16px;
        right: 16px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #38475b;
        border: none;
      }

      .media__buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;

        button {
          height: 38px;
          margin-left: 8px;

          &:first-of-type {
            margin-left: auto;
          }
        }
      }
    }

    &.overview {
      .overview__listed {
        color: ${dustyGray};
      }

      .overview__description {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &.agent {
      .agent__info {
        display: flex;
      }
      .agent__title {
        flex: 1;
      }
      .agent__contact {
        flex: 3;
      }
      .listing-details__agency {
        color: #545454;
      }
      .listing-details__info {
        display: flex;
      }
    }

    &.price-history,
    &.property-details {
      .listing-details__title {
        border: none;
      }
    }

    @media (min-width: ${small}) {
      &.similar-properties {
        margin-top: 8px;
      }
    }

    @media (max-width: ${medium}) {
      .media__images {
        .listing-carousel {
          max-width: initial;
        }
        .img-error {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      .listing-details__name {
        margin-right: 50px;
      }

      .listing-details__list {
        .listing-details__list__item {
          font-size: 14px;
          line-height: 22px;
        }
      }

      .tableview {
        .tableview__cell {
          font-size: 12px;
          line-height: 22px;
        }
      }

      .listview {
        .listview__header {
          margin-bottom: 6px;
          font-size: 12px;
          line-height: 22px;
        }

        .listview__list__group {
          &:first-of-type {
            padding-top: 6px;
          }
          &:last-of-type {
            padding-bottom: 6px;
          }
        }

        .listview__list__caption,
        .listview__list > .listview__list__item {
          font-size: 12px;
          line-height: 20px;
        }
      }

      &.overview {
        .overview__description {
          margin-bottom: 8px;
        }
      }

      &.price-history,
      &.property-details {
        .listing-details__title {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: ${medium}) {
    width: 100%;
    margin-top: 0;
    padding-left: 16px;
    padding-right: 16px;

    .listing__container {
      padding-top: 0;
      flex-flow: column;
    }

    .listing-details {
      &__title {
        margin-bottom: 12px;
      }

      &.media {
        .media__buttons {
          margin-top: 16px;

          button {
            flex: 1;
            justify-content: center;
            &:first-of-type {
              margin-left: 0;
              margin-right: 8px;
            }
          }
        }
      }
    }

    .map .map__container {
      width: initial;
    }

    .floating-listing-contact {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }

  @media (max-width: ${small}) {
    .listing-details {
      &.media {
        .media__buttons {
          margin-top: 18px;
        }
      }

      &.agent {
        .agent__info {
          flex-flow: column;
        }
        .listing-details__name {
          margin-right: 0;
        }
      }

      &__info {
        font-size: 14px;
        grid-template-columns: 3fr 1fr;
      }

      .listing-details__list {
        grid-template-columns: 1fr;
      }
    }

    &.overview {
      .overview__description {
        margin-bottom: 16px;
        font-size: 14px;
      }
    }

    &.map {
      .map__container {
        margin: 0 -16px;
      }
    }

    .read-more {
      position: relative;
      display: block;
      margin-top: 16px;
      padding: 0;

      &:after {
        content: '▼';
        position: absolute;
        margin-top: 2px;
        margin-left: 4px;
        font-size: 7px;
      }

      &.open {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
`;

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  align-self: flex-end;
  padding: 12px 16px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  background: #000;
  border: none;
  color: #fff;
  fill: #fff;
  cursor: pointer;

  &:hover {
    background: #373737;
  }

  .icon {
    margin-right: 10px;
  }
`;
