import React from 'react';
import { Link } from 'react-scroll';

import { styled } from 'styles';

interface ListingNavProps {
  hasSchools?: any;
}

const links = [
  {
    name: 'Overview',
    target: 'overview',
    offset: -260
  },
  {
    name: 'Property Details',
    target: 'property-details'
  },
  {
    name: 'Schools',
    target: 'schools'
  },
  {
    name: 'Affordability',
    target: 'affordability'
  },
  {
    name: 'Similar Homes',
    target: 'similar-properties'
  }
];

const ListingNav: React.FC<ListingNavProps> = ({ hasSchools }) => (
  <StyledListingNav className="listing-nav">
    <ul className="listing-nav__list">
      {links.map(({ name, target, ...props }) => (
        <li key={target} className="listing-nav__list__item">
          {!hasSchools && name === 'Schools' ? null : (
            <span className="listing-nav__list__item-content">
              <Link
                className="listing-nav__link"
                activeClass="active"
                to={target}
                spy
                smooth
                data-cy={`link_${target}`}
                {...props}>
                {name}
              </Link>
            </span>
          )}
        </li>
      ))}
    </ul>
  </StyledListingNav>
);

export default React.memo(ListingNav);

const StyledListingNav = styled.nav`
  border-top: 1px solid ${props => props.theme.colors.borderColor};

  .listing-nav__list {
    margin: 0;
    padding: 0;

    &__item {
      display: inline-block;
    }
    &__item-content {
      margin-right: 24px;
    }
  }

  .listing-nav__link {
    position: relative;
    font-size: 14px;
    line-height: 17px;
    color: ${props => props.theme.colors.dustyGray};
    text-decoration: none;
    cursor: pointer;

    &.active,
    &:hover {
      color: #545454;
    }

    &.active {
      &:before {
        content: '';
        position: absolute;
        top: -2px;
        height: 1px;
        width: 100%;
        background: ${props => props.theme.colors.primary};
      }
    }
  }
`;
