/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import { styled } from 'styles';
import { Button, Icon, Image } from 'components';
import { IMAGE_SIZES } from 'consts';
import noImage from 'assets/images/placeholder_no_image_large.jpg';

interface PhotosProps {
  items: string[];
}

const placeholder = <img src={noImage} className="img-error" alt="no image" />;

const Photos: React.FC<PhotosProps> = ({ items }) => {
  // eslint-disable-next-line no-param-reassign
  const [imagesFullscreen, setImagesFullscreen] = useState<number>();

  return (
    <>
      <StyledPhotos>
        {items.map((item, idx) => (
          <Image
            cloudFront={{ size: IMAGE_SIZES.large, key: item }}
            error={placeholder}
            key={item}
            alt={`photo-${idx + 1}`}
            onClick={() => setImagesFullscreen(idx)}
          />
        ))}
      </StyledPhotos>
      {imagesFullscreen !== undefined && (
        <StyledImagesFullscreen>
          <Button simple onClick={() => setImagesFullscreen(undefined)} className="close-button">
            <Icon name="cross" />
          </Button>
          <Carousel selectedItem={imagesFullscreen} showThumbs={false} showIndicators={false}>
            {items.map((item, idx) => (
              <Image
                className="listing-carousel__slide"
                cloudFront={{ size: IMAGE_SIZES.xl, key: item }}
                error={placeholder}
                key={item}
                alt={`photo-${idx + 1}`}
                onClick={() => setImagesFullscreen(idx)}
              />
            ))}
          </Carousel>
        </StyledImagesFullscreen>
      )}
    </>
  );
};

export default Photos;

const StyledPhotos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 9px;

  img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-columns: 100%;
    grid-gap: 12px;
  }
`;

const StyledImagesFullscreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000;
  display: flex;
  align-items: center;
  overflow: auto;

  .close-button {
    position: absolute;
    top: 20px;
    right: 10px;
    fill: #fff;
    z-index: 1000;
  }

  .carousel-root {
    width: 100%;
    height: 100%;
    max-height: 80%;
    display: flex;
    align-items: center;

    .carousel,
    .slider-wrapper,
    .slider,
    .slide {
      height: 100%;
    }

    .slide {
      display: flex;
      justify-content: center;

      img {
        object-fit: none;
        width: auto;
        height: 100%;
      }
    }

    .carousel-status {
      position: fixed;
      right: 50%;
      transform: translateX(50%);
      font-size: 12px;
      line-height: 24px;
      margin: 16px 0;
    }
  }
`;
