import React, { useState } from 'react';

import { styled } from 'styles';
import { Button, Icon } from 'components';
import { numberFormatter, priceFormatter } from 'utils/formatters';
import { getAddress } from 'utils/listing';
import useSavedListings from 'hooks/useSavedListings';
import SharePopup from './SharePopup';

interface ListingTopSectionProps extends React.HTMLAttributes<HTMLElement> {
  listing: ListingDetails;
}

const ListingTopSection: React.FC<ListingTopSectionProps> = ({ listing }) => {
  const { propertyDetails } = listing;
  const { saveListing, savedListings } = useSavedListings();
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const saved = savedListings?.includes(listing.id);

  return (
    <StyledListingTopSection className="listing-top-section">
      <div className="listing-title">
        <h1 className="listing-title__address1" data-cy="address1">
          {listing.address1}
          {listing.address2 && <>, {listing.address2}</>}
        </h1>
        <h2 className="listing-title__address2" data-cy="address2">
          {getAddress(listing)}
        </h2>
      </div>

      <div className="listing-info">
        <div className="listing-info__block">
          <div className="listing-info__value" data-cy="price">
            {priceFormatter.format(listing.price)}
          </div>
          <div className="listing-info__name">Price</div>
        </div>

        <div className="listing-info__block">
          <div className="listing-info__value" data-cy="baths">
            {propertyDetails.baths}
          </div>
          <div className="listing-info__name">Baths</div>
        </div>

        <div className="listing-info__block">
          <div className="listing-info__value" data-cy="beds">
            {propertyDetails.beds}
          </div>
          <div className="listing-info__name">Beds</div>
        </div>

        <div className="listing-info__block">
          <div className="listing-info__value" data-cy="sqft">
            {numberFormatter.format(propertyDetails.sqft)}
          </div>
          <div className="listing-info__name">sqft</div>
        </div>
      </div>

      <div className="listing-buttons">
        <Button
          onClick={e => saveListing(e, listing.id)}
          primary
          outline={!saved}
          data-cy="button_save">
          <Icon name="heart" /> Save
        </Button>
        <Button primary outline data-cy="button_share" onClick={() => setShareModalOpen(true)}>
          <Icon name="arrow-share" width={12} height={10} /> Share
        </Button>
      </div>
      <SharePopup open={shareModalOpen} listing={listing} onClose={() => setShareModalOpen(false)}>
        Test
      </SharePopup>
    </StyledListingTopSection>
  );
};

export default React.memo(ListingTopSection);

const StyledListingTopSection = styled.section`
  display: flex;
  padding: 18px 0;

  .listing-title {
    h1.listing-title__address1 {
      margin-bottom: 2px;
      font-size: 16px;
      line-height: 24px;
    }

    h2.listing-title__address2 {
      font-size: 12px;
      line-height: 20px;
      color: ${props => props.theme.colors.dustyGray};
    }
  }

  .listing-info {
    display: flex;
    margin: 0 72px 0 auto;

    .listing-info__block {
      padding: 0 16px;
      border-right: 1px solid #000000;
      text-align: center;
      &:last-of-type {
        border-right: none;
      }
    }

    .listing-info__value {
      font-size: 16px;
      line-height: 24px;
    }

    .listing-info__name {
      font-size: 12px;
      line-height: 20px;
      color: ${props => props.theme.colors.dustyGray};
    }
  }

  .listing-buttons {
    display: flex;

    .button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 125px;
      margin-left: 8px;
      font-size: 12px;

      .icon {
        margin-right: 4px;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-wrap: wrap;
    margin-top: 6px;
    margin-bottom: 8px;

    .listing-buttons {
      margin-top: 16px;
      flex-basis: 100%;

      .button {
        width: initial;
        flex: 1;
        margin: 0;

        &:first-of-type {
          margin-right: 16px;
        }
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-flow: column;

    .listing-info {
      margin: 8px 0 16px;
      justify-content: space-between;

      .listing-info__block {
        text-align: left;
        border-right: none;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }
    }

    .listing-buttons {
      justify-content: flex-start;

      .listing-buttons__button {
        margin: 0 8px 0 0;
      }
    }
  }
`;
