import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';

import { styled } from 'styles';
import { Dropdown, Icon, Input, Slider } from 'components';
import { currencyFormatter } from 'utils/formatters';
import PaymentChart from './PaymentChart';

interface PaymentCalculatorProps extends React.HTMLAttributes<HTMLDivElement> {
  data: PaymentValues;
}

type Range = {
  min: number;
  max: number;
};

type InputValues = {
  homePrice: number;
  downpaymentAmount: number;
  interestRate: number;
  years: number;
};

const loanTerms: number[] = [30, 20, 15, 10];
const loanOptions: { value: number; text: React.ReactNode }[] = loanTerms.map(item => ({
  value: item,
  text: `${item} years fixed`
}));

const INTEREST_RATE = 3.51;
const DOWNPAYMENT = 20;
const YEARS = 30;
const MIN_PRICE = 70;
const MAX_PRICE = 130;
const MIN_DOWNPAYMENT = 0;
const MAX_DOWNPAYMENT = 100;
const MIN_INTEREST_RATE = 0.01;
const MAX_INTEREST_RATE = 10;

// calculate % from total
const percentage = (percent: number, total: number, precise?: boolean): number => {
  const result = (percent / 100) * total;
  return precise ? Number(result.toFixed(2)) : Math.round(result);
};

const PaymentCalculator: React.FC<PaymentCalculatorProps> = ({ data }) => {
  const { price, propertyTaxes, homeInsurance, hoa } = data;
  const defaultValues = useMemo(
    () => ({
      homePrice: price,
      downpaymentAmount: percentage(DOWNPAYMENT, price),
      interestRate: INTEREST_RATE,
      years: YEARS
    }),
    [price]
  );

  const [values, setValues] = useState<InputValues>(defaultValues);
  const [errors, setErrors] = useState({} as any);

  const { homePrice, downpaymentAmount, interestRate, years } = values;

  const downpaymentPercentage = useMemo(() => Math.round((downpaymentAmount * 100) / homePrice), [
    downpaymentAmount,
    homePrice
  ]);

  const homePriceRange: Range = useMemo(
    () => ({
      min: percentage(MIN_PRICE, price),
      max: percentage(MAX_PRICE, price)
    }),
    [price]
  );
  const downpaymentRange: Range = useMemo(
    () => ({
      min: percentage(MIN_DOWNPAYMENT, homePrice),
      max: percentage(MAX_DOWNPAYMENT, homePrice)
    }),
    [homePrice]
  );
  const interestRateRange: Range = {
    min: MIN_INTEREST_RATE,
    max: MAX_INTEREST_RATE
  };

  const estimatedPayment = useMemo(() => {
    const i = interestRate / 12 / 100;
    const s = homePrice - downpaymentAmount;
    const n = years * 12;

    // formula
    const p = s * (i + i / ((1 + i) ** n - 1));
    return parseFloat(p.toFixed(2));
  }, [interestRate, homePrice, downpaymentAmount, years]);

  const total = Number(propertyTaxes + homeInsurance + hoa + estimatedPayment);

  const isValidPayment =
    !Number.isNaN(estimatedPayment) && estimatedPayment > 0 && !Object.keys(errors).length;
  const formattedPayment = isValidPayment ? currencyFormatter.format(estimatedPayment) : '';
  const chartLabel =
    isValidPayment && !Number.isNaN(total) ? (
      <>
        {currencyFormatter.format(total)}
        <span>/month</span>
      </>
    ) : (
      'Provide valid values'
    );
  const chartData = [
    {
      title: 'Principal & Interests',
      value: isValidPayment ? estimatedPayment : 0,
      color: '#D1D9E8'
    },
    { title: 'Property Taxes', value: propertyTaxes || 0, color: '#9EB2CD' },
    { title: 'Home Insurance', value: homeInsurance || 0, color: '#6A778A' },
    { title: 'HOA', value: hoa || 0, color: '#38475B' }
  ];

  const validate = ({ min, max, name, value }) => {
    const numberValue = parseFloat(value);
    const inputErrors = { ...errors };

    if (!numberValue) {
      inputErrors[name] = 'Required';
    } else if (Number.isNaN(numberValue)) {
      inputErrors[name] = 'Invalid value';
    } else if (numberValue > max) {
      inputErrors[name] = 'Entered value is too large';
    } else if (numberValue < min) {
      inputErrors[name] = 'Entered value is too small';
    } else {
      delete inputErrors[name];
    }

    setErrors(inputErrors);
  };

  const handleInputChange = e => {
    const { target } = e;
    const value = parseFloat(target.value) || '';
    const { name } = target;

    validate(target);

    const updatedValues = {
      ...values,
      [name]: value
    };

    if (name === 'downpaymentPercentage') {
      updatedValues.downpaymentAmount = percentage(value || 0, homePrice);
    }

    setValues(updatedValues);
  };

  const handleSliderChange = name => value => {
    setErrors({});
    const updatedValues = { ...values, [name]: Number(value) };

    setValues(updatedValues);
  };

  const handleYearsClick = (value: number, close: () => void) => () => {
    setValues({
      ...values,
      years: value
    });
    close();
  };

  const getErrorMessage = (name: string) => {
    const error = errors[name];

    if (!error) return null;

    return (
      <p className="form__error" data-cy={`error_${errors[name]}`}>
        <Icon name="warning" />
        {errors[name]}
      </p>
    );
  };

  return (
    <StyledPaymentCalculator className="payment-calculator">
      <p className="payment-calculator__heading">Calculate your monthly mortgage payments</p>
      <p>
        Your Estimated Payment is:{' '}
        <span className="payment-calculator__total" data-cy="estimated-payment">
          {formattedPayment}
        </span>
      </p>
      <div>
        <div className="payment-calculator__form">
          <StyledFormControl className={clsx('form-control', { error: errors.homePrice })}>
            <Input
              name="homePrice"
              id="homePrice"
              before="$"
              label="Home Price"
              type="number"
              min={homePriceRange.min}
              max={homePriceRange.max}
              value={homePrice}
              onChange={handleInputChange}
              data-cy="input_home-price"
            />

            <StyledSlider
              min={homePriceRange.min}
              max={homePriceRange.max}
              value={homePrice || 0}
              className="payment-calculator__slider"
              onChange={handleSliderChange('homePrice')}
              data-cy="slider_home-price"
            />

            {getErrorMessage('homePrice')}
          </StyledFormControl>

          <StyledFormControl
            className={clsx('form-control', {
              error: errors.downpaymentAmount || errors.downpaymentPercentage
            })}>
            <div className="downpayment">
              <Input
                name="downpaymentAmount"
                id="downpaymentAmount"
                before="$"
                label="Down Payment"
                type="number"
                min={downpaymentRange.min}
                max={downpaymentRange.max}
                value={downpaymentAmount}
                onChange={handleInputChange}
                className="downpayment__amount"
                data-cy="input_downpayment-amount"
              />

              <NumberFormat
                name="downpaymentPercentage"
                id="downpaymentPercentage"
                decimalSeparator="."
                allowNegative={false}
                allowLeadingZeros={false}
                suffix="%"
                isNumericString
                min={MIN_DOWNPAYMENT}
                max={MAX_DOWNPAYMENT}
                value={downpaymentPercentage}
                onChange={handleInputChange}
                className="number-format downpayment__percentage"
                data-cy="input_downpayment-percentage"
              />
            </div>

            <StyledSlider
              min={downpaymentRange.min}
              max={downpaymentRange.max}
              value={downpaymentAmount || 0}
              onChange={handleSliderChange('downpaymentAmount')}
              data-cy="slider_downpayment"
            />

            {getErrorMessage('downpaymentAmount') || getErrorMessage('downpaymentPercentage')}
          </StyledFormControl>

          <StyledFormControl className={clsx('form-control', { error: errors.interestRate })}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="interestRate">Interest Rate</label>
            <NumberFormat
              id="interestRate"
              name="interestRate"
              decimalSeparator="."
              allowNegative={false}
              allowLeadingZeros={false}
              suffix="%"
              isNumericString
              min={interestRateRange.min}
              max={interestRateRange.max}
              value={interestRate}
              onChange={handleInputChange}
              className="input number-format"
              data-cy="input_interest-rate"
            />

            <StyledSlider
              min={interestRateRange.min}
              max={interestRateRange.max}
              step={0.01}
              value={interestRate || 0}
              onChange={handleSliderChange('interestRate')}
              data-cy="slider_interest-rate"
            />

            {getErrorMessage('interestRate')}
          </StyledFormControl>

          <StyledDropdown
            label="Loan Type"
            value={`${years} years fixed`}
            render={({ close }) => (
              <ul className="dropdown__options">
                {loanOptions.map(option => (
                  <li
                    className="dropdown__option"
                    key={option.value}
                    onClick={handleYearsClick(option.value, close)}
                    data-cy={`option_${option.value}`}>
                    {option.value === years ? <Icon name="check" /> : null}
                    {option.text}
                  </li>
                ))}
              </ul>
            )}
          />
        </div>

        <div className="payment-calculator__chart">
          <PaymentChart data={chartData} label={chartLabel} data-cy="payment-chart" />
          <div className="payment-calculator__details">
            <div className="payment-calculator__details__row principal">
              <span className="indicator" />
              Principal & Interests:&nbsp;
              <span
                className="payment-calculator__details__value"
                data-cy="principal-and-interests">
                {formattedPayment}
              </span>
            </div>
            <div className="payment-calculator__details__row taxes">
              <span className="indicator" />
              Property Taxes:&nbsp;
              <span className="payment-calculator__details__value" data-cy="taxes">
                {currencyFormatter.format(propertyTaxes)}
              </span>
            </div>
            <div className="payment-calculator__details__row insurance">
              <span className="indicator" />
              Home Insurance:&nbsp;
              <span className="payment-calculator__details__value" data-cy="home-insurance">
                {currencyFormatter.format(homeInsurance)}
              </span>
            </div>
            <div className="payment-calculator__details__row hoa">
              <span className="indicator" />
              HOA:&nbsp;
              <span className="payment-calculator__details__value" data-cy="hoa">
                {currencyFormatter.format(hoa)}
              </span>
            </div>

            <a
              href="https://www.bankrate.com/mortgages/mortgage-rates/"
              target="_blank"
              rel="noreferrer"
              className="payment-calculator__details__link"
              data-cy="link_rates">
              See today's Mortgage Rates
            </a>
          </div>
        </div>
      </div>
    </StyledPaymentCalculator>
  );
};

export default React.memo(PaymentCalculator);

const borderRadius = props => props.theme.misc.borderRadius;
const small = props => props.theme.breakpoints.sm;
const medium = props => props.theme.breakpoints.md;

const StyledPaymentCalculator = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #545454;

  .number-format {
    height: 36px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: ${borderRadius};

    &,
    &:focus {
      border: 1px solid ${props => props.theme.colors.borderColor};
      outline: 0;
    }
  }

  .downpayment {
    display: flex;
    align-items: center;

    &__amount {
      input {
        border-radius: ${borderRadius} 0 0 ${borderRadius};
      }
    }

    &__percentage {
      max-width: 50px;
      padding: 0 10px;
      text-align: center;
      border-left: none;
      border-radius: 0 ${borderRadius} ${borderRadius} 0;
    }
  }

  .payment-calculator__total {
    font-weight: 500;
    color: #000;
  }

  .payment-calculator__chart {
    display: flex;
    align-items: center;
    margin-top: 28px;
  }

  .indicator {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 19px;
    border-radius: 100%;
  }

  .payment-calculator__form {
    display: flex;
    flex-flow: wrap;

    .input label,
    .dropdown label {
      font-weight: normal;
    }

    .dropdown {
      margin-top: 24px;
    }

    .form__error {
      display: flex;
      align-items: center;
      margin: 12px 0 4px;
      font-size: 12px;
      line-height: 16px;
      color: ${props => props.theme.colors.errorRed};
      white-space: nowrap;

      .icon {
        margin-right: 4px;
      }
    }
  }

  .payment-calculator__details {
    margin-left: 44px;

    &__row {
      display: flex;
      align-items: center;
      margin: 4px 0;

      &.principal .indicator {
        background: #d1d9e8;
      }

      &.taxes .indicator {
        background: #9eb2cd;
      }

      &.insurance .indicator {
        background: #6a778a;
      }

      &.hoa .indicator {
        background: #38475b;
      }
    }

    &__value {
      font-weight: 500;
      color: #000;
    }

    &__link {
      display: block;
      margin-top: 12px;
      font-size: 12px;
      line-height: 24px;
      color: inherit;
    }
  }

  @media (max-width: ${medium}) {
    .payment-calculator__form {
      justify-content: space-between;

      .dropdown {
        margin-top: 0;
      }
    }
  }

  @media (max-width: ${small}) {
    .payment-calculator__form {
      flex-direction: column;

      .dropdown {
        margin-top: 0;
      }
    }

    .payment-calculator__chart {
      margin: 0 15px;
      display: block;
    }

    .payment-calculator__details {
      margin-top: 28px;
      margin-left: 0;

      &__row {
        margin: 0 0 10px;
        font-size: 15px;
        line-height: 22px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
`;

const StyledFormControl = styled.div`
  margin-right: 20px;
  width: min-content;

  .input {
    margin-bottom: 20px;

    input {
      height: 36px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .input .input__component-wrapper__before {
    margin-top: 3px;
  }

  label {
    display: block;
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    color: #000;
  }

  &.error {
    input,
    input:focus {
      border-color: ${props => props.theme.colors.errorRed};
    }
  }

  @media (max-width: ${medium}) {
    width: 183px;

    &::last-of-type {
      margin-right: 0;
    }

    .input {
      width: 100%;
    }

    .dropdown {
      width: 183px;
    }
  }

  @media (max-width: ${small}) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
  }
`;

const StyledSlider = styled(Slider)`
  .track {
    height: 3px;
    bottom: 4.5px;
    &-0 {
      background: ${props => props.theme.colors.primary};
    }
    &-1 {
      background: #aab7c9;
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  min-width: 183px;

  .dropdown__menu {
    padding: 0;
  }

  .dropdown__label {
    font-weight: normal;
    color: #000;
  }

  .dropdown__options {
    margin: 2px 0;
    padding: 0;
    list-style: none;

    .dropdown__option {
      display: flex;
      align-items: center;
      padding: 10px 6px 10px 12px;
      cursor: pointer;

      &:hover {
        background: #f5f5f5;
      }

      .icon-check {
        margin: 0 8px 0 4px;
        fill: #239457;
      }
    }
  }

  &.open {
    .dropdown__button {
      border: 1px solid #afafaf;
    }
  }

  @media (max-width: ${small}) {
    width: 100%;
    margin-bottom: 24px;
  }
`;
