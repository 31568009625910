import React from 'react';

import { styled } from 'styles';

const ScheduleTour = () => {
  return (
    <StyledScheduleTour className="schedule-tour">
      <h4 className="schedule-tour__heading">Schedule a tour</h4>
    </StyledScheduleTour>
  );
};

export default React.memo(ScheduleTour);

const StyledScheduleTour = styled.section``;
