import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import { styled } from 'styles';

const PaymentChart = ({ data, label, ...props }) => (
  <StyledPaymentChart className="payment-chart">
    <PieChart
      data={data}
      lineWidth={20}
      labelPosition={0}
      animate
      style={{ height: 268, width: 268 }}
      {...props}
    />
    <div className="payment-chart__label">{label}</div>
  </StyledPaymentChart>
);

export default React.memo(PaymentChart);

const StyledPaymentChart = styled.div`
  position: relative;
  text-align: center;

  .payment-chart__label {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 22px;
    color: #000;

    & > span {
      margin-top: 2px;
      font-size: 14px;
    }
  }
`;
