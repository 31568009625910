import React from 'react';

import { Components } from 'sitedata';
import { styled } from 'styles';

const ListingLayout: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children }) => {
  return (
    <StyledListingLayout>
      <Components.Header hasSearch />
      {children}
      <Components.Footer />
    </StyledListingLayout>
  );
};

export default React.memo(ListingLayout);

const StyledListingLayout = styled.div`
  background: ${props => props.theme.colors.superLightGray};

  .header {
    background: ${props => props.theme.colors.white};
  }

  .footer {
    margin: 0 21px;
    padding-top: 44px;
    border-top: 1px solid ${props => props.theme.colors.lightGray};
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .footer {
      padding-bottom: 90px;
    }
  }
`;
