import React, { useContext } from 'react';
import clsx from 'clsx';
import { Link, navigate } from 'gatsby';
import { Button, Tooltip, Icon } from 'components';

import { styled } from 'styles';
import { info } from 'sitedata';
import { calculateDiscount } from 'utils/listing';
// import useAuthContext from 'hooks/useAuthContext';
import { AuthContext, AuthModalContext } from 'auth';
import { useListingContext } from './context';

const LinkButton = Button as any;

interface OfferBlockProps {
  className?: string;
}

const OfferBlock: React.FC<OfferBlockProps> = ({ className }) => {
  const listing = useListingContext();
  // const { authorized } = useAuthContext();
  const cashback = calculateDiscount(listing.price);
  const { authorized } = useContext(AuthContext);
  const { setAuthModalOpen } = useContext(AuthModalContext);

  const handleClick = e => {
    if (!authorized) {
      e.preventDefault();
      setAuthModalOpen(true);
    }
  };

  return (
    <StyledOfferBlock className={clsx('offer-block', className)}>
      <h3 className="offer-block__title">
        {cashback && (
          <span>
            Buy with {info.title}, save up to <span className="offer-block__price">{cashback}</span>
          </span>
        )}
        <Tooltip
          placement="bottom-end"
          trigger={
            <Icon name="tooltip-trigger" className="tooltip-trigger" width="14" height="14" />
          }>
          <p className="tooltip-content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur varius elit,
            vel aliquet diam. Etiam nec orci vel leo semper iaculis non nec metus. Praesent posuere
            mauris sed.
          </p>
        </Tooltip>
      </h3>
      <p className="offer-block__description">We’ll guide you through every step.</p>
      <LinkButton
        className="offer-block__btn"
        primary
        outline
        fluid
        as="a"
        href={`/buy-process?id=${listing.id}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}>
        Make an offer
      </LinkButton>
    </StyledOfferBlock>
  );
};

export default OfferBlock;

const StyledOfferBlock = styled.div`
  padding: 12px;
  border: 1px solid ${props => props.theme.colors.lightGray};
  border-radius: ${props => props.theme.misc.borderRadius};
  margin: 0 0 32px;
  .offer-block {
    &__title {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      margin: 0 0 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__price {
      color: ${props => props.theme.colors.primary};
      font-weight: 600;
    }
    &__description {
      font-size: 12px;
      line-height: 16px;
      color: #979797;
      margin: 0 0 12px;
    }
  }
  .tooltip-trigger {
    circle {
      fill: ${props => props.theme.colors.secondaryLight};
    }
    path {
      fill: ${props => props.theme.colors.primary};
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin: 0 0 16px;
  }
`;
