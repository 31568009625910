import React from 'react';

import { styled } from 'styles';
import Map, { Marker } from 'components/Map';

interface ListingMapProps extends React.HTMLAttributes<HTMLDivElement> {
  listing: ListingDetails;
}

const ListingMap: React.FC<ListingMapProps> = ({ listing }) => {
  const getURLSearchParams = (value: { [key: string]: string }) =>
    `/search?${new URLSearchParams(value).toString()}`;
  const showMap = listing.longitude && listing.latitude;

  return (
    <StyledListingMap>
      <div className="map__breadcrumbs">
        <a
          href={getURLSearchParams({
            search_string: listing.city,
            city: listing.city
          })}
          className="map__breadcrumbs__level"
          target="_blank"
          rel="noreferrer"
          data-cy="location-city">
          {listing.city}
        </a>
        <a
          href={getURLSearchParams({
            search_string: listing.postalCode,
            postal_code: listing.postalCode
          })}
          className="map__breadcrumbs__level"
          target="_blank"
          rel="noreferrer"
          data-cy="location-postal-code">
          {listing.postalCode}
        </a>
        <a
          href={getURLSearchParams({
            search_string: listing.neighborhood,
            area: listing.neighborhood
          })}
          className="map__breadcrumbs__level"
          target="_blank"
          rel="noreferrer"
          data-cy="location-neighborhood">
          {listing.neighborhood}
        </a>
        <span className="map__breadcrumbs__level address" data-cy="location-address">
          {listing.address1}
        </span>
      </div>
      {showMap ? (
        <div className="map__container">
          <Map
            center={{
              lat: listing.latitude,
              lng: listing.longitude
            }}>
            <Marker lat={listing.latitude} lng={listing.longitude} />
          </Map>
        </div>
      ) : (
        <h3 className="map__placeholder">Map/Street view is not available.</h3>
      )}
    </StyledListingMap>
  );
};

export default React.memo(ListingMap);

const StyledListingMap = styled.div`
  .map__breadcrumbs {
    margin-bottom: 12px;

    &__level {
      position: relative;
      padding-right: 16px;
      font-size: 14px;
      line-height: 22px;
      color: ${props => props.theme.colors.primary};
      text-decoration: none;
      cursor: pointer;

      &:not(:last-child):after {
        content: '';
        width: 0;
        height: 0;
        border-top: 3px solid transparent;
        border-left: 5px solid ${props => props.theme.colors.thunderBlack};
        border-bottom: 3px solid transparent;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }

      &.address {
        color: ${props => props.theme.colors.thunderBlack};
        cursor: default;
      }
    }
  }

  .map__container {
    width: 100%;
    height: 280px;
    border: 1px solid rgba(231, 231, 231, 0.5);

    .map {
      height: 100%;
    }
  }

  .map__placeholder {
    margin-top: 20px;
    text-align: center;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .map__breadcrumbs {
      margin-bottom: 18px;

      &__level {
        margin-bottom: 2px;

        &.address {
          display: block;
        }
      }
    }
  }
`;
