import React from 'react';

import { styled } from 'styles';
import { Button } from 'components';

const FloatingListingContact = ({ onButtonClick }) => {
  return (
    <StyledFloatingListingContact className="floating-listing-contact">
      <Button
        primary
        outline
        onClick={() => onButtonClick('schedule-tour')}
        data-cy="button_schedule-tour">
        Schedule Tour
      </Button>
      <Button
        primary
        outline
        onClick={() => onButtonClick('contact-agent')}
        data-cy="button_contact-agent">
        Contact Agent
      </Button>
    </StyledFloatingListingContact>
  );
};

export default React.memo(FloatingListingContact);

const StyledFloatingListingContact = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  justify-content: space-between;
  padding: 12px 16px;
  background: #fff;
  border-radius: ${props => props.theme.misc.borderRadius};
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);

  .button {
    font-size: 12px;
  }
`;
